.Mui-selected > span {
  font-weight: bold;
  color: #ee4e48;
}

.ais-Hits-item {
  height: auto;
  border-bottom: 1px solid #eee;
  padding-top: 5px;
  padding-bottom: 5px;
}

.selected {
  background-color: #ddd;
}

.hit-title > .ais-Highlight,
.hit-fragmentText > .ais-Highlight,
.hit-tag > .ais-Highlight,
.hit-lemma > .ais-Highlight,
.hit-originalText > .ais-Highlight {
  font-size: 1.15rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075rem;
}

.hit-subtitle > .ais-Highlight,
.hit-contentTitle > .ais-Highlight,
.hit-fragmentTextInQuestion > .ais-Highlight {
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9rem;
}

.hit-answerText {
  color: rgba(0, 0, 0, 1);
  font-size: 1rem;
}

.hit-tags,
.hit-dateAndPublic,
.hit-supportedTranslationLanguages,
.hit-langAndKeepable,
.hit-textTranslation,
.hit-textTranslationsByGengo,
.hit-lemmaIds,
.hit-categoryAndPublic,
.hit-learningLanguageCodes,
.hit-languageCodeToName,
.hit-originalTextTranslations,
.hit-relatedWords,
.hit-difficulty,
.hit-hidden,
.hit-dateAndPublished {
  color: #666;
  font-size: 0.85rem;
}

em {
  background-color: rgb(250, 175, 168);
  color: #ff0000;
}
